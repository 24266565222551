import React from "react";
//import MainMenuController from "../js/MainMenuController";
import "@popperjs/core";

import "../styles/main.scss";

import webTechnologiesIcon from "../images/icons/svg/web-service.svg";
import devopsIcon from "../images/icons/svg/service-provider.svg";
import dataAnalyticsIcon from "../images/icons/svg/data-analytics.svg";
import phoneIcon from "../images/icons/svg/message-phone-chat.svg";
import aiIcon from "../images/icons/svg/artificial-intelligence-ai.svg";
import vrIcon from "../images/icons/svg/virtual-reality-vr.svg";
import quantumIcon from "../images/icons/svg/quantum.svg";

import team from "../images/careers/team.jpeg";
import career_1 from "../images/careers/career_1.jpg";
import career_2 from "../images/careers/career_2.jpg";
import career_3 from "../images/careers/career_3.jpg";
import career_4 from "../images/careers/career_4.jpg";
import career_5 from "../images/careers/career_5.jpg";
import career_6 from "../images/careers/career_6.jpg";
import career_7 from "../images/careers/career_7.jpg";
import career_8 from "../images/careers/career_8.jpg";
import career_9 from "../images/careers/career_9.jpg";
import career_10 from "../images/careers/career_10.jpg";
import career_11 from "../images/careers/career_11.jpg";
import career_12 from "../images/careers/career_12.jpg";
import career_13 from "../images/careers/career_13.jpg";
import career_14 from "../images/careers/career_14.jpg";
import career_15 from "../images/careers/career_15.jpg";
import career_16 from "../images/careers/career_16.jpg";
import career_17 from "../images/careers/career_17.jpg";
import career_18 from "../images/careers/career_18.jpg";

import Metadata from "../js/Metadata";
import Navbar from "../js/Navbar";
import Footer from "../js/Footer";
import Accordion from "../js/layout/jobs_accordion/accordion";
import AiAcademyToast from "../js/layout/ai_academy_toast";

const CareerPage = () => {
    const description =
        "We are looking for motivated individuals with all levels of experience. Check our open positions or contact us on jobs@themama.ai, if you couldn't find your dream job on our list.";
    const icons = [
        {
            icon: webTechnologiesIcon,
            description: "Web technologies, frontend skills, design",
        },
        {
            icon: devopsIcon,
            description: "Devops",
        },
        {
            icon: dataAnalyticsIcon,
            description: "Mathematics, data science, data analytics",
        },
        {
            icon: phoneIcon,
            description:
                "NLP, audio processing, speech, understanding, dialog systems",
        },
        {
            icon: aiIcon,
            description: "Deep Learning and Machine Learning",
        },
        {
            icon: vrIcon,
            description: "Vision, virtual reality",
        },
        {
            icon: quantumIcon,
            description: "Quantum",
        },
    ];
    const lifeImages = [
        career_1,
        career_3,
        career_4,
        career_5,
        career_6,
        career_7,
        career_8,
        career_18,
        career_9,
        career_10,
        career_11,
        career_2,
        career_12,
        career_13,
        career_14,
        career_15,
        career_16,
        career_17,
    ];

    return (
        <div className="container-max-width">
            <Metadata
                title="THE MAMA AI CAREERS"
                url="https://themama.ai/careers"
                description={description}
            />
            <Navbar />
            <section className="header-section section">
                <div className="container">
                    <div className="row header-section__header align-items-center">
                        <div className="col-xxl-5 col-xl-6">
                            <h1 className="pb-3 header-section__title">
                                Come work with us!
                            </h1>
                            <p className="pb-2 header-section__text">
                                Are you a good problem solver? Do you like to
                                code? Are you passionate about AI and new
                                technologies?
                            </p>
                            <p className="pb-2 header-section__text">
                                We are looking for motivated individuals with
                                all levels of experience - check our open
                                positions below.
                            </p>
                            <p className="header-section__text">
                                If you couldn't find your dream job on our list,
                                contact us at{" "}
                                <a
                                    href="mailto:jobs@themama.ai"
                                    className="text--color-2"
                                >
                                    jobs@themama.ai
                                </a>
                                . We are always ready to find a place in our
                                company for talented people.
                            </p>
                            <a
                                href="#job-openings"
                                className="btn btn-primary text-decoration-none text-white mt-3 english-btn"
                            >
                                See open positions
                            </a>
                        </div>
                        <div className="col-xl-6 offset-xxl-1 d-none d-xl-block text-end">
                            <img
                                className="img-fluid header-section__img"
                                src={team}
                                alt="Come work with us!"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section
                id="job-openings-section"
                className="pt-3 pb-3 section section--light"
            >
                <div id="job-openings" className="section__anchor" />
                <div className="container">
                    <div className="row pt-5 pb-3 text-center">
                        <h2 className="job-openings__title">Open positions</h2>
                        <p className="body-2-text pt-2">
                            Join our team and grow with us! Help us to build a
                            unique company providing the best AI products and
                            solutions.
                        </p>
                    </div>
                    <div className="row pt-3 pb-5">
                        <Accordion parentId="jobs-accordion" />
                    </div>
                </div>
            </section>

            <section
                id="fields-of-expertise-section"
                className="fields-of-expertise section pt-4"
            >
                <div className="container">
                    <div className="row pt-5 text-center">
                        <h1> Fields of Expertise </h1>
                    </div>
                    <div className="row pb-5 pt-3 text-center justify-content-center">
                        {icons.map((iconItem, index) => (
                            <div
                                key={index}
                                className="col-6 col-sm-4 col-lg-4 col-xxl-3"
                            >
                                <div className="we-are-hiring-section__icon-wrapper">
                                    <img
                                        className="we-are-hiring-section__icon"
                                        src={iconItem.icon}
                                        alt={iconItem.description}
                                    />
                                    <span className="mt-3">
                                        {iconItem.description}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section
                id="we-offer"
                className="pt-3 we-offer-section section section--light"
            >
                <div className="container">
                    <div className="row pt-5 pb-3 text-center">
                        <h1>We Offer</h1>
                    </div>
                    <div className="row pt-3 pb-5 justify-content-evenly">
                        <div className="col-lg-5 position-relative">
                            <ul className="custom-ul">
                                <li>
                                    <p className="we-offer-section__text">
                                        Flexible working conditions - there are
                                        many options for our cooperation from
                                        internship and thesis cooperation to
                                        full‑time job
                                    </p>
                                </li>
                                <li>
                                    <p className="we-offer-section__text">
                                        Possibility to work remotely or from the
                                        office in Prague - Old Town
                                    </p>
                                </li>
                                <li>
                                    <p className="we-offer-section__text">
                                        Informal and friendly working
                                        environment we are truly proud of
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-5 position-relative">
                            <ul className="custom-ul">
                                <li>
                                    <p className="we-offer-section__text">
                                        Opportunity to gain new skills working
                                        on a state of the art AI/ML, with a
                                        seasoned team of brilliant industry
                                        experts
                                    </p>
                                </li>
                                <li>
                                    <p className="we-offer-section__text">
                                        Possibility to grow with the company and
                                        be part of the next big thing
                                    </p>
                                </li>
                                <li>
                                    <p className="we-offer-section__text">
                                        Regular hackathons, team outings, board
                                        games sessions...
                                    </p>
                                </li>
                                <li>
                                    <p className="we-offer-section__text">
                                        Competitive compensation
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section
                id="life-in-the-mama-ai"
                className="life-in-the-mama-ai-section section section--light"
            >
                <div className="container">
                    <div className="row pt-5 pb-3 text-center">
                        <h1> Life in The MAMA AI </h1>
                    </div>
                    <div className="row pt-3 pb-5 justify-content-center tile-container">
                        {lifeImages.map((item, i) => {
                            return (
                                <div className="col-lg-4 col-sm-6" key={i}>
                                    <img
                                        src={item}
                                        alt="life in the mama ai"
                                        className="img-fluid tile-container__img"
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
            {<AiAcademyToast />}
            {<Footer />}
        </div>
    );
};

export default CareerPage;
