import React, { useState } from "react";
import AccordionItem from "../accordion_item";
import useSwr from "swr";

const Accordion = () => {
    const { data } = useSwr("/api/open-positions");

    const openPositions = data?.data || [];

    return (
        <>
            {openPositions.map((post) => (
                <div
                    className="accordion accordion-flush job-posting-accordion"
                    key={post.id}
                >
                    <AccordionItem
                        id={post.id}
                        title={post.attributes.title}
                        type={post.attributes.type}
                        location={post.attributes.location}
                        body={post.attributes.body}
                    />
                </div>
            ))}
        </>
    );
};

export default Accordion;
