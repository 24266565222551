import React from "react";
import "./index.scss";
import ReactMarkdown from "react-markdown";

const AccordionItem = (props) => {
    return (
        <>
            <div className="accordion-item jobs-accordion-item mb-4">
                <div
                    className="accordion-header jobs-accordion-item__header"
                    id={"heading-" + props.id}
                >
                    <button
                        className="accordion-button jobs-accordion-item__button collapsed p-4 p-lg-5"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapse-" + props.id}
                        aria-expanded="true"
                        aria-controls={"collapse-" + props.id}
                    >
                        <div className="d-block w-100">
                            <div className="row align-items-center">
                                <div className="col-lg-3">
                                    <p className="body-2-text mb-0">
                                        <strong>{props.title}</strong>
                                    </p>
                                </div>
                                <div className="col-lg-3">
                                    <p className="body-2-text mb-0">
                                        {props.type}
                                    </p>
                                </div>
                                <div className="col-lg-3">
                                    <p className="body-2-text mb-0">
                                        {props.location}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </button>
                </div>

                <div
                    id={"collapse-" + props.id}
                    className={`accordion-collapse collapse p-4 p-lg-5`}
                    aria-labelledby={"heading" + props.id}
                >
                    <div className="jobs-accordion-item__body">
                        <div className="row">
                            <div className="col-12 body-2-text">
                                <ReactMarkdown>{props.body}</ReactMarkdown>
                            </div>

                            <div className="col-12 text-end">
                                <a
                                    className="btn-primary text-white btn m-auto mt-4 text-decoration-none"
                                    role="button"
                                    href={
                                        "mailto:jobs@themama.ai?subject=Application for (" +
                                        (props.id + 1) +
                                        ") " +
                                        props.title
                                    }
                                >
                                    Email us at jobs@themama.ai
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccordionItem;
